<template>
  <div id="app" :style="cssVars">
    <router-view/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapGetters([
      'themeColors',
    ]),
    cssVars() {
      return {
        '--primary': this.themeColors.primary,
        '--secondary': this.themeColors.secondary,
      }
    },
  },
  methods: {
    ...mapActions([
      'setApplicationScope',
    ])
  },
  mounted() {
    this.setApplicationScope();
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'FSPimlicoWeb-Regular';
  src: url('./assets/fonts/hinted-FSPimlico.eot');
  src: url('./assets/fonts/hinted-FSPimlico.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/hinted-FSPimlico.woff2') format('woff2'),
  url('./assets/fonts/hinted-FSPimlico.woff') format('woff'),
  url('./assets/fonts/hinted-FSPimlico.ttf') format('truetype'),
  url('./assets/fonts/hinted-FSPimlico.svg#FSPimlico') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FSPimlicoWeb-Bold';
  src: url('./assets/fonts/hinted-FSPimlico-Bold.eot');
  src: url('./assets/fonts/hinted-FSPimlico-Bold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/hinted-FSPimlico-Bold.woff2') format('woff2'),
  url('./assets/fonts/hinted-FSPimlico-Bold.woff') format('woff'),
  url('./assets/fonts/hinted-FSPimlico-Bold.ttf') format('truetype'),
  url('./assets/fonts/hinted-FSPimlico-Bold.svg#FSPimlico-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FSPimlicoWeb-Black';
  src: url('./assets/fonts/hinted-FSPimlico-Black.eot');
  src: url('./assets/fonts/hinted-FSPimlico-Black.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/hinted-FSPimlico-Black.woff2') format('woff2'),
  url('./assets/fonts/hinted-FSPimlico-Black.woff') format('woff'),
  url('./assets/fonts/hinted-FSPimlico-Black.ttf') format('truetype'),
  url('./assets/fonts/hinted-FSPimlico-Black.svg#FSPimlico-Black') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FSPimlicoWeb-BlackItalic';
  src: url('./assets/fonts/hinted-FSPimlico-BlackItalic.eot');
  src: url('./assets/fonts/hinted-FSPimlico-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/hinted-FSPimlico-BlackItalic.woff2') format('woff2'),
  url('./assets/fonts/hinted-FSPimlico-BlackItalic.woff') format('woff'),
  url('./assets/fonts/hinted-FSPimlico-BlackItalic.ttf') format('truetype'),
  url('./assets/fonts/hinted-FSPimlico-BlackItalic.svg#FSPimlico-BlackItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

%font-family-FSPimlicoWeb-Black
{
  font-family: 'FSPimlicoWeb-Black', sans-serif;
}

%font-family-FSPimlicoWeb-BlackItalic
{
  font-family: 'FSPimlicoWeb-BlackItalic', sans-serif;
}

%font-family-FSPimlicoWeb-Bold
{
  font-family: 'FSPimlicoWeb-Bold', sans-serif;
}

%font-family-FSPimlicoWeb-Regular
{
  font-family: 'FSPimlicoWeb-Regular', sans-serif;
}

#app {
  @extend %font-family-FSPimlicoWeb-Regular;
  color: #000;

  b, strong {
    @extend %font-family-FSPimlicoWeb-Bold;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
  }

  p {
    font-size: 1.25rem;
    line-height: 1.2;
    margin-bottom: 1.9rem;
  }

  input, button {
    height: 35px;
    margin-bottom: 10px;

    &:focus,
    &:active,
    &:active:focus {
      box-shadow: none !important;
    }
  }

  input {
    &:focus {
      border-color: #ced4da;
    }
  }
}
</style>
