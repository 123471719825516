'use strict';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import x5GMaps from 'x5-gmaps'

Vue.config.productionTip = false;

window.axios = require('axios');

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.use(Vuelidate);

Vue.use(x5GMaps, {
  key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  libraries: [
    'geometry',
  ],
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
