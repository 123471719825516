import Vue from 'vue';
import Vuex from 'vuex';
import {gmaps} from 'x5-gmaps';
import {APPLICATION_SCOPE, THEME_COLORS} from '@/config';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    services: [],
    selectedService: null,
    applicationScope: null,
    centerLat: Number,
    centerLng: Number,
    currentLat: Number,
    currentLng: Number,
    defaultLat: 51.50779291103208,
    defaultLng: -0.12792193506819088,
    defaultMaxDistanceFromLocationFas: 30,
    defaultMaxDistanceFromLocationFan: 1000,
    audienceTypeFanOnly: [6], // 6 = RBL FAN only
    showCurrentLocation: false,
    showMap: false,
    showServices: false,
    themeColorPrimary: null,
    themeColorSecondary: null,
    validSearch: false,
    serviceAudiences: [],
    serviceTypes: [],
    selectedServiceAudienceId: null,
    selectedServiceTypeId: null,
    searchTerm: ''
  },
  mutations: {
    loadServices(state, services) {
      services.forEach(service => {
        service.searchResult = {};
      });
      state.services = services;
    },
    clearLocation(state) {
      state.showCurrentLocation = false;
      state.currentLat = state.centerLat;
      state.currentLng = state.centerLng;
    },
    clearSelectedService(state) {
      state.selectedService = null;
    },
    resetValidSearch(state) {
      state.validSearch = false;
    },
    currentLocation(state, position) {
      state.currentLat = position.lat;
      state.currentLng = position.lng;
    },
    defaultLocation(state) {
      state.currentLat = state.defaultLat;
      state.currentLng = state.defaultLng;
    },
    setServices(state, services) {
      state.services = services;
    },
    hideCurrentLocation(state) {
      state.showCurrentLocation = false;
    },
    hideServices(state) {
      state.showServices = false;
    },
    selectService(state, serviceId) {
      state.selectedService = serviceId;
    },
    setApplicationScope(state, applicationScope) {
      state.applicationScope = applicationScope;
    },
    setInitialCenterLocation(state) {
      state.centerLat = state.currentLat;
      state.centerLng = state.currentLng;
    },
    setThemeColors(state, themeColors) {
      state.themeColorPrimary = themeColors.primary;
      state.themeColorSecondary = themeColors.secondary;
    },
    setValidSearch(state) {
      state.validSearch = true;
    },
    showCurrentLocation(state) {
      state.showCurrentLocation = true;
    },
    showMap(state) {
      state.showMap = true;
    },
    showServices(state) {
      state.showServices = true;
    },
    updateCenterLocation(state, position) {
      state.centerLat = position.lat;
      state.centerLng = position.lng;
    },
    loadServiceAudiences(state, serviceAudiences) {
      state.serviceAudiences = [];
      state.serviceAudiences.push({value: null, text: '- Select group/service audience -'});
      state.serviceAudiences.push({value: null, text: 'All audiences'});
      serviceAudiences.forEach(serviceAudience => {
        if (state.applicationScope === APPLICATION_SCOPE.FAS && state.audienceTypeFanOnly.includes(serviceAudience.id) ) {
          return;
        }
        state.serviceAudiences.push({value: serviceAudience.id, text: serviceAudience['audience_name']});
      });
    },
    loadServiceTypes(state, serviceTypes) {
      state.serviceTypes = [];
      state.serviceTypes.push({value: null, text: '- Select the type of group or service -'});
      state.serviceTypes.push({value: null, text: 'All types'});
      serviceTypes.forEach(serviceType => {
        state.serviceTypes.push({value: serviceType.id, text: serviceType['service_type_name']});
      });
    },
    setSelectedServiceAudience(state, selectedServiceAudienceId) {
      state.selectedServiceAudienceId = selectedServiceAudienceId;
    },
    setSelectedServiceType(state, selectedServiceTypeId) {
      state.selectedServiceTypeId = selectedServiceTypeId;
    },
    setSearchTerm(state, searchTerm) {
      state.searchTerm = searchTerm;
    },
  },
  actions: {
    loadServices({commit, state}) {
      return new Promise((resolve, reject) => {

        let url = process.env.VUE_APP_FAS_API_URL
          + '/'
          + process.env.VUE_APP_FAS_API_VERSION
          + '/locations?expand=service'
          + '&filter[application_scope]=' + APPLICATION_SCOPE.ALL + ',' + state.applicationScope;

        url += (state.selectedServiceAudienceId ? '&filter[service_audience_id]=' + state.selectedServiceAudienceId : '');

        url += (state.selectedServiceTypeId ? '&filter[service_type_id]=' + state.selectedServiceTypeId : '');

        window.axios
          .get(url)
          .then((response) => {
            commit('loadServices', response.data);
            resolve();
          })
          .catch(e => {
            commit('showServices');
            reject(e);
          });
      });
    },
    loadServiceAudiences({commit}) {
      return new Promise((resolve, reject) => {
        window.axios
          .get(process.env.VUE_APP_FAS_API_URL
            + '/'
            + process.env.VUE_APP_FAS_API_VERSION
            + '/service-audiences'
          )
          .then((response) => {
            commit('loadServiceAudiences', response.data);
            resolve();
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    loadServiceTypes({commit}) {
      return new Promise((resolve, reject) => {
        window.axios
          .get(process.env.VUE_APP_FAS_API_URL
            + '/'
            + process.env.VUE_APP_FAS_API_VERSION
            + '/service-types'
          )
          .then((response) => {
            commit('loadServiceTypes', response.data);
            resolve();
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    clearSelectedService({commit}) {
      commit('clearSelectedService');
    },
    clearLocation({commit}) {
      commit('clearLocation');
      commit('resetValidSearch');
    },
    locate({commit}) {
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          alert('Geolocation is not supported by this browser');
          reject();
          return;
        }
        commit('hideServices');
        navigator.geolocation.getCurrentPosition(
          (position) => {
            commit('currentLocation', {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            });
            commit('showMap');
            commit('showCurrentLocation');
            resolve();
          },
          () => {
            commit('defaultLocation');
            commit('showMap');
            commit('showCurrentLocation');
            resolve();
          },
          {timeout: 10000}
        );
      })
    },
    searchLocation({commit}, {lat, lng}) {
      return new Promise((resolve) => {
        commit('hideServices');
        commit('currentLocation', {
          lat: Number(lat),
          lng: Number(lng)
        });
        commit('showCurrentLocation');
        resolve();
      });
    },
    setCurrentLocation({commit}, {lat, lng}) {
      return new Promise((resolve) => {
        commit('currentLocation', {
          lat: lat(),
          lng: lng()
        });
        resolve();
      });
    },
    /**
     * This is used for the first call to search for the service by name (or postcode) so an exact match(es)
     * maybe found.  So this then resets the map pin to where the first location is so that it actually shows
     * on the map
     *
     * @param commit
     * @param state
     * @param serviceName
     * @param serviceAudienceId
     * @returns {Promise<unknown>}
     */
    searchServiceName({commit, state}, serviceName) {
      return new Promise((resolve, reject) => {
        commit('hideServices');
        commit('hideCurrentLocation');

        let url = process.env.VUE_APP_FAS_API_URL
          + '/'
          + process.env.VUE_APP_FAS_API_VERSION
          + '/locations?expand=service'
          + '&filter[application_scope]=' + APPLICATION_SCOPE.ALL + ',' + state.applicationScope
          + '&filter[all_names_and_postcode]=' + serviceName;

        url += (state.selectedServiceAudienceId ? '&filter[service_audience_id]=' + state.selectedServiceAudienceId : '');

        url += (state.selectedServiceTypeId ? '&filter[service_type_id]=' + state.selectedServiceTypeId : '');

        window.axios
          .get(url)
          .then((response) => {
            if (!response.data.length) {
              reject(new Error('No matching service name found.'));
              return;
            }
            commit('loadServices', response.data);

            /* If the first service has a physical location then move to it, else stay where we are */
            if (state.services[0] && state.services[0].lat && state.services[0].long) {
              commit('currentLocation', {
                lat: Number(state.services[0].lat),
                lng: Number(state.services[0].long)
              });
              commit('showCurrentLocation');
              resolve();
            } else {
              reject(new Error('No physical services found - falling back to location search next'));
            }
          })
          .catch(() => {
            reject(new Error('Service not found'));
          });
      });
    },
    selectService({commit}, serviceId) {
      return new Promise((resolve) => {
        commit('selectService', serviceId);
        resolve();
      });
    },
    setApplicationScope({commit}) {
      return new Promise((resolve) => {
        switch (location.hostname) {
          case process.env.VUE_APP_FAS_HOST:
            commit('setApplicationScope', APPLICATION_SCOPE.FAS);
            commit('setThemeColors', THEME_COLORS.FAS);
            break;
          default:
            commit('setApplicationScope', APPLICATION_SCOPE.FAN);
            commit('setThemeColors', THEME_COLORS.FAN);
        }
        resolve();
      });
    },
    setInitialCenterLocation({commit}) {
      return new Promise((resolve) => {
        commit('setInitialCenterLocation');
        resolve();
      });
    },
    calculateSearchResultsDistances({commit, state}) {
      return new Promise((resolve) => {
        gmaps().then(maps => {
          let services = state.services;
          const METRES_IN_MILE = 1609.344;

          for (let i = 0; i < services.length; i++) {
            /* If this is geographical, else it doesn't make sense */
            if (services[i].lat != null && services[i].long != null) {
              let distanceInMetres = maps.geometry.spherical.computeDistanceBetween(
                new maps.LatLng(state.currentLat, state.currentLng),
                new maps.LatLng(services[i].lat, services[i].long),
              );
              services[i].searchResult.distance = (distanceInMetres / METRES_IN_MILE).toFixed(2);
            }
          }

          commit('setServices', services);
          resolve();
        });
      });
    },
    /**
     * Will go through the results and prune out any over the set maximum distance limit
     */
    pruneResultsOverDistance({commit, state}) {
      return new Promise((resolve) => {
        let services = state.services;
        let retainedServices = [];
        for (let i = 0; i < services.length; i++) {
          let maxDistance = state.defaultMaxDistanceFromLocationFan
          if (state.applicationScope === APPLICATION_SCOPE.FAS) {
            maxDistance = state.defaultMaxDistanceFromLocationFas
          }
          if (services[i].always_show === 1 || services[i].searchResult.distance <= maxDistance) {
            retainedServices.push(services[i]);
          }
        }
        commit('setServices', retainedServices);
        resolve();
      });
    },
    showServices({commit}) {
      commit('showServices');
    },
    sortSearchResults({commit, state}) {
      return new Promise((resolve) => {
        let services = state.services;

        services.sort((a, b) => {
          return a.searchResult.distance - b.searchResult.distance
        });

        for (let i = 0; i < services.length; i++) {
          services[i].searchResult.position = (i + 1).toString();
        }

        commit('setServices', services);
        commit('setValidSearch');
        commit('showServices');
        resolve();
      });
    },
    updateCenterLocation({commit}, {lat, lng}) {
      commit('updateCenterLocation', {
        lat: lat(),
        lng: lng()
      });
    },
    setSelectedServiceAudienceId({commit}, selectedServiceAudienceId) {
      commit('setSelectedServiceAudience', selectedServiceAudienceId);
    },
    setSelectedServiceTypeId({commit}, selectedServiceTypeId) {
      commit('setSelectedServiceType', selectedServiceTypeId);
    },
    setSearchTerm({commit}, searchTerm) {
      commit('setSearchTerm', searchTerm);
    },
  },
  modules: {},
  getters: {
    applicationScope(state) {
      return state.applicationScope;
    },
    listServices(state) {
      return state.services;
    },
    currentLocation(state) {
      return {
        'lat': state.currentLat,
        'lng': state.currentLng
      }
    },
    isValidSearch(state) {
      return state.validSearch;
    },
    selectedService(state) {
      return state.selectedService;
    },
    showCurrentLocation(state) {
      return state.showCurrentLocation;
    },
    showMap(state) {
      return state.showMap;
    },
    showServices(state) {
      return state.showServices;
    },
    themeColors(state) {
      return {
        'primary': state.themeColorPrimary,
        'secondary': state.themeColorSecondary,
      }
    },
    serviceTypes(state) {
      return state.serviceTypes;
    },
    serviceAudiences(state) {
      return state.serviceAudiences;
    },

  },
})
