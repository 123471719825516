const APPLICATION_SCOPE_FAN = 'find-a-nurse';
const APPLICATION_SCOPE_FAS = 'young-dementia';
const APPLICATION_SCOPE_ALL = 'all';

export const APPLICATION_SCOPE = Object.freeze({
  ALL: APPLICATION_SCOPE_ALL,
  FAN: APPLICATION_SCOPE_FAN,
  FAS: APPLICATION_SCOPE_FAS,
});

const THEME_COLOR_PRIMARY_FAN = '#00b0b9';
const THEME_COLOR_SECONDARY_FAN = '#d40f8c';

const THEME_COLOR_PRIMARY_FAS = '#00b0b9';
const THEME_COLOR_SECONDARY_FAS = '#d40f8c';

export const THEME_COLORS = Object.freeze({
  FAN: {
    primary: THEME_COLOR_PRIMARY_FAN,
    secondary: THEME_COLOR_SECONDARY_FAN,
  },
  FAS: {
    primary: THEME_COLOR_PRIMARY_FAS,
    secondary: THEME_COLOR_SECONDARY_FAS,
  },
});
